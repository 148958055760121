<template>
    <div class="timepicker" id="timepicker">
        <ul class="list-unstyled">
            <li v-for="(date, index) in availableDates" v-bind:key="index">
                <div class="form-check" @mouseover="emitDate(date.timeValue)">
                    <input class="form-check-input" type="radio" name="serviceTimeRadio" :id="'serviceTimeRadio_' + index" :value="date.timeValue" @change="emitTime(date.prepLocation)" v-model="selectedUserDate">
                    <label :class="getClass(index)" v-bind:for="'serviceTimeRadio_' + index">
                        <span class="demi">{{date.startDay}}</span> <span class="demi">{{date.startDate}}</span> <span v-html="date.bookingText" />
                    </label>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
    import {parseISO, addMinutes, format, areIntervalsOverlapping, addBusinessDays, isToday, isSameDay} from 'date-fns'
    import { de } from 'date-fns/locale';
    import { getHolidays } from 'feiertagejs';

    export default {
        name: 'Timepicker',
        props: ['selectedDate', 'selectedDuration', 'selectedYear'],
        data() {
            return {
                date: format(addBusinessDays(new Date(), 1), 'yyyy-MM-dd'),
                availableDates: [],
                datesCheck: [],
                resetDate: false,
                selectedUserDate: '',
                unavailableDates: []
            }
        },
        watch: {
            selectedDate: function(newVal){
                this.date = format(newVal, 'yyyy-MM-dd');
                this.resetDate = true;
                this.unavailableDates = [];
                this.getPossibleDatesWrapper(this.date);
                this.setUnavailableDates();
            },
            selectedDuration: function(){
                this.resetDate = true;
                this.unavailableDates = [];
                this.getPossibleDatesWrapper(this.date);
                this.setUnavailableDates();
            },
            selectedYear: function(newVal){
                this.date = format(newVal, 'yyyy-MM-dd');
                this.resetDate = true;
                this.unavailableDates = [];
                this.getPossibleDatesWrapper(this.date);
                this.setUnavailableDates();
            },
        },
        mounted() {
            this.getPossibleDatesWrapper();
            this.setUnavailableDates();
        },
        methods: {
            getClass(index){
                var current = this.availableDates[index],
                    next = this.availableDates[index + 1];

                if(next && current.startDay !== next.startDay){
                    return "form-check-label day-break";
                }else{
                    return "form-check-label";
                }
            },
            getPossibleDatesWrapper(date=null){
                if(!date){
                    date = this.date;
                }
                else{
                    if(this.resetDate){
                        this.datesCheck = [];
                        this.availableDates = [];
                    }
                }

                this.getPossibleDates(date);

                var ISODate = parseISO(date),
                    nextDay,
                    i;

                for (i = 1; i < 7; i++) {

                    nextDay = addBusinessDays(ISODate, i)

                    this.getPossibleDates(format(nextDay, 'yyyy-MM-dd'));
                }

                if(this.resetDate){
                    document.querySelector(".timepicker").scrollTop = 0;
                }
            },
            getPossibleDates(date){

                let workTime = [7, 8, 9, 10,11, 12, 13, 14, 15, 16, 17, 18];

                var bookedInterval = {"1": [], "2": [], "3": [], "4": []},
                    dateKey = format(parseISO(date), 'dd.MM.yyyy'),
                    bookingText,
                    prep_location,
                    morningFound,
                    afternoonFound,
                    allDayFound,
                    booked,
                    startTime,
                    endTime,
                    overlapping;

                if(this.bookedDates && this.bookedDates[date]){
                    if(this.bookedDates[date][1]){
                        this.bookedDates[date][1].forEach(dates => {
                            bookedInterval[1].push({'start': dates.start, 'end': dates.end});
                        })
                    }
                    if(this.bookedDates[date][2]){
                        this.bookedDates[date][2].forEach(dates => {
                            bookedInterval[2].push({'start': dates.start, 'end': dates.end});
                        })
                    }
                    if(this.bookedDates[date][3]){
                        this.bookedDates[date][3].forEach(dates => {
                            bookedInterval[3].push({'start': dates.start, 'end': dates.end});
                        })
                    }
                    if(this.bookedDates[date][4]){
                        this.bookedDates[date][4].forEach(dates => {
                            bookedInterval[4].push({'start': dates.start, 'end': dates.end});
                        })
                    }
                }

                if(!this.datesCheck.includes(dateKey)){

                    if(isToday(parseISO(date)) && parseInt(format(Date.now(), 'H')) > 7 ){
                        // no appointment in the past of today
                        var i,
                            index,
                            hour = parseInt(format(Date.now(), 'H'));

                        for (i = 7; i < hour; i++) {
                            index = workTime.indexOf(i);
                            if (index > -1) {
                              workTime.splice(index, 1);
                            }
                        }
                    }else{
                        workTime = [7, 8, 9, 10,11, 12, 13, 14, 15, 16, 17];
                    }

                    workTime.forEach(time => {
                        booked = false;
                        startTime = parseISO(date + " " + ("0" + time).slice(-2) + ":00:00");
                        endTime = addMinutes(startTime, this.selectedDuration);


                        if(!isSameDay(startTime, endTime) || parseInt(format(endTime, 'H')) > 17 ){
                            // nobody works after six
                            return;
                        }

                        if(morningFound && parseInt(format(startTime, 'H')) < 12 ){
                            // appointment found -> done
                            return;
                        }

                        if(afternoonFound || allDayFound ){
                            // appointment found -> done
                            return;
                        }

                        prep_location = 1;

                        if(bookedInterval[1].length > 0){
                            bookedInterval[1].forEach(interval => {
                                overlapping = areIntervalsOverlapping(
                                  { start: startTime, end: endTime },
                                  { start: parseISO(interval.start), end: parseISO(interval.end) }
                                )
                                if(overlapping){
                                    prep_location = 2;
                                    if(bookedInterval[2].length > 0){
                                        bookedInterval[2].forEach(interval => {
                                            overlapping = areIntervalsOverlapping(
                                              { start: startTime, end: endTime },
                                              { start: parseISO(interval.start), end: parseISO(interval.end) }
                                            )
                                            if(overlapping){
                                                prep_location = 3;
                                                if(bookedInterval[3].length > 0){
                                                    bookedInterval[3].forEach(interval => {
                                                        overlapping = areIntervalsOverlapping(
                                                          { start: startTime, end: endTime },
                                                          { start: parseISO(interval.start), end: parseISO(interval.end) }
                                                        )
                                                        if(overlapping){
                                                            prep_location = 4;
                                                            if(bookedInterval[4].length > 0){
                                                                bookedInterval[4].forEach(interval => {
                                                                    overlapping = areIntervalsOverlapping(
                                                                      { start: startTime, end: endTime },
                                                                      { start: parseISO(interval.start), end: parseISO(interval.end) }
                                                                    )
                                                                    if(overlapping){
                                                                        booked = true;
                                                                        return;
                                                                    }
                                                                })
                                                            }else{
                                                                return;
                                                            }
                                                        }
                                                    })
                                                }else{
                                                    return;
                                                }
                                            }
                                        })
                                    }else{
                                        return;
                                    }
                                }
                            })
                            if(!booked){

                                if(parseInt(format(startTime, 'H')) < 12 ){
                                    bookingText = "Vormittag<span class='smaller'>*</span>";
                                    morningFound = true;
                                }

                                if(parseInt(format(startTime, 'H')) >= 12 ){
                                    bookingText = "Nachmittag<span class='smaller'>**</span>";
                                    afternoonFound = true;
                                }

                                if(this.selectedDuration >= 300){
                                    bookingText = "Ganztägig<span class='smaller'>***</span>";
                                    allDayFound = true;
                                }

                                this.availableDates.push({'prepLocation': prep_location, 'timeValue': format(startTime, 'yyyy-MM-dd HH:mm'), 'startDay': format(startTime, 'EEEEEE', {locale: de}), 'startDate': dateKey, 'bookingText': bookingText})

                            }else{
                                if(!this.unavailableDates.includes(format(startTime, 'dd.MM.yyyy'))){
                                    this.unavailableDates.push(format(startTime, 'dd.MM.yyyy'));
                                }
                            }
                        }else{

                            if(parseInt(format(startTime, 'H')) < 12 ){
                                bookingText = "Vormittag<span class='smaller'>*</span>";
                                morningFound = true;
                            }

                            if(parseInt(format(startTime, 'H')) >= 12 ){
                                bookingText = "Nachmittag<span class='smaller'>**</span>";
                                afternoonFound = true;
                            }

                            if(this.selectedDuration >= 300){
                                bookingText = "Ganztägig<span class='smaller'>***</span>";
                                allDayFound = true;
                            }
                            this.availableDates.push({'prepLocation': prep_location, 'timeValue': format(startTime, 'yyyy-MM-dd HH:mm'), 'startDay': format(startTime, 'EEEEEE', {locale: de}), 'startDate': dateKey, 'bookingText': bookingText})
                        }

                    })

                    this.datesCheck.push(dateKey);
                }
            },
            emitTime(prepLocation){
                this.$emit('time', {'date': this.selectedUserDate, 'prepLocation': prepLocation})
            },
            emitDate(dateTime){
                this.resetDate = false;
                this.getPossibleDatesWrapper(dateTime);
                this.setUnavailableDates();
            },
            setUnavailableDates() {
                var holidays = getHolidays(format(this.selectedYear, 'yyyy'),'SN')
                holidays.forEach(days => {
                    if(!this.unavailableDates.includes(format(days.date, 'dd.MM.yyyy'))){
                        this.unavailableDates.push(format(days.date, 'dd.MM.yyyy'));
                    }
                })
                this.availableDates.forEach(value => {
                    var index = this.unavailableDates.indexOf(value.startDate);
                    if (index > -1) {
                      this.unavailableDates.splice(index, 1);
                    }
                })
                this.$emit('lockDays', this.unavailableDates)
            }

        },
        computed: {
            bookedDates() { return this.$store.state.bookedDates },
        }
    }
</script>
<style lang="scss">

    .timepicker{
        z-index: 100;
        background: #fff;
        border: 1px solid #ccc;
        height: 370px;
        overflow-y: scroll;
        padding-left:15px;
        padding-right:0;
        width: 100%;
        position:relative;


        @media(max-width: 768px) {
            padding-left:15px;
        }

        li{
            font-size: 19px;
            letter-spacing: 0.53px;
            line-height:57px;

            @media(max-width: 768px) {
                font-size: 16px;
            }

            label{
                border-bottom: thin solid lightgray;
                width:90%;

                @media(max-width: 768px) {
                    margin-left: 10px;
                }

                &.day-break{
                    border-bottom: 2px solid #000;
                }
                span{
                    display:inline-block;
                    margin:0 5px;

                    @media(max-width: 768px) {
                    margin:0;
                    }
                }
            }

            .form-check-input{

                margin-top:1.2em;

                @media(max-width: 768px) {
                    margin-top:1.4em;
                }

                &:checked+label{
                    font-family: 'futura-pt-bold';
                    font-size: 18px;

                    @media(max-width: 768px) {
                        font-size: 15px;
                    }
                    span{
                        margin:0 3px;
                    }
                }
            }
        }
    }

    .smaller{
        font-size:80%;
        letter-spacing: 0.01em;
    }

</styles>